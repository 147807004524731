import { LabelSearchInput } from './call_diagnosis';
import { OutOfScopeReason } from '../../types/disposition.types';
import { getInitialValue, getFilteredOptions } from '../../util/call_disposition_helpers';

interface OutOfScopeReasonInputProps {
    reason: OutOfScopeReason;
    outOfScopeReasons: OutOfScopeReason[];
    index: number;
    outOfScopeInputOptions: { value: string; label: string }[];
    deleteOutOfScopeReason: (reason: OutOfScopeReason) => void;
    editOutOfScopeReason: (prevReason: OutOfScopeReason, newReason: OutOfScopeReason) => void;
}

export const OutOfScopeReasonInput = ({
    reason,
    outOfScopeReasons,
    index,
    outOfScopeInputOptions,
    deleteOutOfScopeReason,
    editOutOfScopeReason,
}: OutOfScopeReasonInputProps): JSX.Element => {
    return (
        <LabelSearchInput
            key={reason.code}
            ariaLabel={`out of scope reason options ${index + 1}`}
            placeholder="Search here"
            label="Reason(s) for Out of Scope Discussion"
            deletable={outOfScopeReasons.length > 1}
            handleDelete={() => deleteOutOfScopeReason(reason)}
            handleChange={val => {
                editOutOfScopeReason(reason, {
                    code: val.value,
                    label: val.label,
                });
            }}
            options={getFilteredOptions(reason, outOfScopeReasons, outOfScopeInputOptions)}
            required={index === 0}
            initialValue={getInitialValue(reason, outOfScopeReasons)}
        />
    );
};
