import { FC, useEffect, useMemo } from 'react';
import PageHeader from '../../components/page_header/page_header';
import { TabLink, PageLink, Column, Box, TabbedComponentContainer, styled } from '@streem/ui-react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import { createGetCompanyStore, GetCompanyProvider } from '../../hooks/detail_store_hooks';
import { Observer } from 'mobx-react';
import {
    CompanySettingsProvider,
    createCompanySettingsStore,
} from '../../hooks/company_settings_hooks';
import { APINotFound } from '../../components/routing/error_handlers';
import SettingsList from './settings_page';
import CustomizationsPage from './customizations_page';
import config from '../../config';
import { PageColumn } from '../page_column';
import { FeatureLab } from './feature_lab';

const CompanyDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
    const companyCode = useActiveCompanyCode();
    const companyStore = createGetCompanyStore();
    const companySettingsStore = createCompanySettingsStore(companyCode);
    useEffect(() => {
        const promises = [companyStore.fetch(companyCode), companySettingsStore.loadSettings()];
        return () => promises.forEach(p => p.cancel());
    }, [companyCode, companyStore, companySettingsStore]);

    const tabLinks: TabLink[] = useMemo(
        () => [
            {
                link: () => (
                    <Box marginLeft={5} key="settings-link">
                        <PageLink
                            onClick={() => history.push('settings')}
                            active={location.pathname.includes('/settings')}
                            title="Settings"
                        />
                    </Box>
                ),
            },
            {
                link: () => (
                    <Box marginLeft={5} key="customizations-link">
                        <PageLink
                            onClick={() => history.push('customizations')}
                            active={location.pathname.endsWith('/customizations')}
                            title="Customizations"
                        />
                    </Box>
                ),
            },
            {
                link: () => (
                    <Box marginLeft={5} key="feature-lab-link">
                        <PageLink
                            onClick={() => history.push('featurelab')}
                            active={location.pathname.endsWith('/featurelab')}
                            title="Feature Lab"
                        />
                    </Box>
                ),
            },
        ],
        [history, location],
    );

    return (
        <GetCompanyProvider value={companyStore}>
            <CompanySettingsProvider value={companySettingsStore}>
                <APINotFound stores={[companySettingsStore, companyStore]} />
                <PageColumn dataTestId="settings-page">
                    <HeaderWrapper>
                        <Box pb={4} px={4}>
                            <Observer>
                                {() => (
                                    <PageHeader
                                        title={companyStore.result?.name}
                                        subtitle={config.getUrlRef(companyStore.result?.code)}
                                    />
                                )}
                            </Observer>
                        </Box>
                    </HeaderWrapper>
                    <TabbedComponentContainer tabLinks={tabLinks} data-testid="company-detail-tabs">
                        <Switch>
                            <Route
                                key={'settings'}
                                path={['/companies/:company/settings', '/settings']}
                                exact={false}
                                component={SettingsList}
                            />
                            <Route
                                key={'customizations'}
                                path={['/companies/:company/customizations', '/customizations']}
                                exact={false}
                                component={CustomizationsPage}
                            />
                            <Route
                                key={'feature_lab'}
                                path={['/companies/:company/featurelab', '/featurelab']}
                                exact={false}
                                component={FeatureLab}
                            />
                        </Switch>
                    </TabbedComponentContainer>
                </PageColumn>
            </CompanySettingsProvider>
        </GetCompanyProvider>
    );
};

const HeaderWrapper = styled(Column)(() => ({
    flexShrink: 0,
    zIndex: 1,
}));

export default CompanyDetailPage;
