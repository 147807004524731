import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { CallNotificationListener, InCall } from '@streem/sdk-react';
import { useGlobalStore } from '../../hooks/use_global_context';
import { useSurveys } from '../../hooks/use_surveys';
import { useGetCompanyStore } from '../../hooks/detail_store_hooks';
import { CallNotification } from './call_notification';
import { preferredFallbackCodecsInOrder } from '../../util/constants';

export function InCallListener(): JSX.Element {
    const { sdkStore, authStore, companySettingsStore } = useGlobalStore();
    const history = useHistory();
    const displaySurveys = useSurveys();
    const companyStore = useGetCompanyStore();

    const getRoomCallLogHistory = useCallback(() => {
        return sdkStore.getRoomCallLogHistory();
    }, [sdkStore]);

    return useObserver(() => {
        const {
            gpsEnabled,
            webAREnabled: arEnabled,
            hideFormattedAddressEnabled: hideFormattedAddress,
            faceToFaceModeEnabled,
            experimentalFeatures,
            screenshareEnabled,
            expertAvailabilityEnabled,
            anonymousShareEnabled,
            allowPauseResumeRecording,
            chimeNetworkAdaptationEnabled,
            chimeLowBandwidthOptimizationEnabled,
            preferredCodecs: preferredVideoCodecs,
            roomNoteCharLimit,
            streemshotNoteCharLimit,
        } = companySettingsStore;
        const isEmbed = history.location.pathname.startsWith('/embed');

        const groupReservationCallSource = sdkStore.groupReservationCallSource;
        const brandSettings = {
            brandName: groupReservationCallSource?.name,
            brandLogoUrl: groupReservationCallSource?.logoUrl,
            customTheme: groupReservationCallSource?.customTheme,
        };

        if (authStore.isAdmin || authStore.isAgent) {
            return (
                <>
                    {!sdkStore.roomSession?.current && sdkStore.userSession.current && (
                        <CallNotificationListener
                            userSession={sdkStore.userSession.current}
                            callConfig={{
                                faceToFaceModeEnabled,
                                experimentalFeatures,
                                videoPlatformNetworkAdaptationEnabled:
                                    chimeNetworkAdaptationEnabled,
                                chimeLowBandwidthOptimizationEnabled,
                                preferredVideoCodecs:
                                    preferredVideoCodecs ?? preferredFallbackCodecsInOrder,
                                showReservationCallDetailYn:
                                    companySettingsStore.ahsVirtualExpertEnabled,
                                brandSettings,
                            }}
                            autoAcceptInvites={isEmbed}
                            SystemNotificationComponent={CallNotification}
                        />
                    )}
                    {sdkStore.roomSession?.current && (
                        <InCall
                            getRoomCallLogHistory={getRoomCallLogHistory}
                            toolSettings={{
                                gpsEnabled,
                                arEnabled,
                                hideFormattedAddress,
                                screenshareEnabled,
                                faceToFaceModeEnabled,
                                expertAvailabilityEnabled,
                                anonymousShareEnabled,
                                allowPauseResumeRecording,
                                isEmbed,
                                roomNoteCharLimit,
                                streemshotNoteCharLimit,
                            }}
                            companyInfo={{ companyName: companyStore.result.name }}
                            roomSession={sdkStore.roomSession.current}
                            userSession={sdkStore.userSession.current}
                            onEnd={async roomId => {
                                if (isEmbed) {
                                    history.push(
                                        `/embed/${roomId}/postcall` + window.location.search,
                                    );
                                } else {
                                    if (!companySettingsStore.roomOutcomeFeatureEnabled) {
                                        await displaySurveys(authStore.userId, roomId.toString());
                                    }
                                    history.push(`/mycalls/${roomId}`);
                                }
                            }}
                        />
                    )}
                </>
            );
        } else {
            return <></>;
        }
    });
}
