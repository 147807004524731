import {
    AlertIcon,
    Box,
    Column,
    ListRowFeaturedCell,
    Row,
    styled,
    useTheme,
} from '@streem/ui-react';
import React from 'react';
import { SettingRow } from '../../components/companies/settings/settings_page.styles';
import { SettingField } from '../../components/companies/settings/setting_field';
import HackerIcon from '../../images/hacker.svg';
import { Setting } from '../../util/company_settings';

interface HeaderProps {
    size: 'large' | 'medium';
}

const Header = styled.div<HeaderProps>`
    font-size: ${props => (props.size === 'large' ? '1.5rem' : '1.25rem')};
    font-family: inherit;
    font-weight: 700;
    text-transform: none;
    color: ${props => props.color};
    overflow: hidden;
    line-height: normal;
`;

const AlertBody = styled.div`
    font-size: 0.8rem;
    font-family: 'Andale Mono';
    font-weight: 400;
    text-transform: none;
    flex-basis: 75%;
    margin: 0 25px;
    color: ${props => props.color};
    overflow: hidden;
    line-height: normal;
`;

const AlertBox: React.FC<{ companyName: string }> = ({ companyName }) => {
    const theme = useTheme();
    return (
        <Row
            p={5}
            m={5}
            backgroundColor={theme.colors.phosphorGreen}
            style={{ alignItems: 'center' }}
        >
            <AlertIcon color={theme.colors.black} />
            <Header size={'medium'} color={theme.colors.black} style={{ margin: '0 12px' }}>
                Alert
            </Header>
            <AlertIcon color={theme.colors.black} />
            <AlertBody color={theme.colors.black}>
                Be aware: any changes made here will immediately take effect for {companyName}. If
                you’re unsure about what something will do, do not touch it.
            </AlertBody>
        </Row>
    );
};

interface Props {
    settings: Setting[];
    companyName: string;
}

const DeveloperSettingsPanel: React.FC<Props> = ({ settings, companyName }) => {
    const theme = useTheme();

    return (
        <Box
            p={6}
            m={4}
            backgroundColor={theme.colors.black}
            color={theme.colors.phosphorGreen}
            fontFamily={'Silom'}
            style={{ border: `2px solid ${theme.colors.phosphorGreen}` }}
        >
            <Header size="large" color="phosphorGreen">
                Special Developer Settings
            </Header>
            <AlertBox companyName={companyName} />
            {settings.map(setting => (
                <SettingRow
                    key={setting.name}
                    data-testid={`${setting.name.toLowerCase()}-row`}
                    monochrome
                >
                    <Column marginRight={'5px'} flexShrink={0}>
                        <img alt="Hacker icon" height={45} width={42} src={HackerIcon} />
                    </Column>
                    <ListRowFeaturedCell
                        monochrome
                        headline={setting.label}
                        subHeadline={setting.description}
                        marginRight="auto"
                        paddingRight="16px"
                        width="400px"
                    />
                    <SettingField monochrome setting={setting} width="40%" />
                </SettingRow>
            ))}
        </Box>
    );
};

export default DeveloperSettingsPanel;
