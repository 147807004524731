import { useObservable } from '@streem/sdk-react';
import { styled } from '@streem/ui-react';
import React from 'react';
import { WallItem } from 'streem-sdk-protobuf';
import { useDetailSession } from '../../hooks/detail_session_hooks';
import { gpsPosToGoogleMaps } from '../../util/routing';
import { DecoratedLink } from '../links/decorated_link';
import { Subheader } from '../typography/typography';

/**
 * Displays the GPS location of a call as a decorated link
 * Links to google maps
 */
export const CallDetailsLocation: React.FC<{
    hideAddress: boolean;
}> = ({ hideAddress }) => {
    const detailSession = useDetailSession();

    const [latestLocation] = useObservable(detailSession.geolocation.latestLocation);

    return (
        <LocationWrapper>
            <Subheader size="mediumLarge" data-testid="call-details-location-heading">
                Location
            </Subheader>
            <LocationLink
                hideFormattedAddress={hideAddress}
                latestGpsPosition={latestLocation?.gpsPosition ?? undefined}
            />
        </LocationWrapper>
    );
};

/**
 * Returns formatted address & coordinates OR just coodinates depending on company setting
 *
 * @param hideFormattedAddress - whether the company setting to hide formatted address is enabled
 * @param latestGpsPosition - GPS coordinates associated with the call
 */
const LocationLink: React.FC<{
    hideFormattedAddress: boolean;
    latestGpsPosition?: WallItem.IGPSPosition;
}> = ({ hideFormattedAddress, latestGpsPosition }) => {
    if (!latestGpsPosition) {
        return (
            <DecoratedLink
                data-testid="call-details-location-link"
                icon={'GPSIcon'}
                displayText="No GPS location found"
            />
        );
    }

    if (hideFormattedAddress) {
        return (
            <address aria-label={formattedCoordinates(latestGpsPosition)}>
                <DecoratedLink
                    data-testid="call-details-location-link"
                    icon={'GPSIcon'}
                    displayText={formattedCoordinates(latestGpsPosition)}
                    link={gpsPosToGoogleMaps(latestGpsPosition)}
                />
            </address>
        );
    }
    return (
        <address aria-label={latestGpsPosition.formattedAddress ?? ''}>
            <DecoratedLink
                data-testid="call-details-location-link"
                icon={'GPSIcon'}
                displayText={latestGpsPosition.formattedAddress ?? ''}
                link={gpsPosToGoogleMaps(latestGpsPosition)}
                subText={formattedCoordinates(latestGpsPosition)}
            />
        </address>
    );
};

const formattedCoordinates = (gpsPosition: WallItem.IGPSPosition) => {
    return `${gpsPosition.latitude?.toFixed(5)}, ${gpsPosition.longitude?.toFixed(5)}`;
};

const LocationWrapper = styled.div`
    margin-bottom: 24px;
`;
