import { useLayoutEffect, useMemo } from 'react';
import { Box, Row } from '@streem/ui-react';
import { streem } from 'streem-sdk-protobuf';
import { useFormikContext } from 'formik';
import {
    Disposition,
    RefundReason,
    OutOfScopeReason,
    RoomOutcomeFormPayload,
} from '../../types/disposition.types';
import { LabelSearchInput } from './call_diagnosis';
import { useGlobalStore } from '../../hooks/use_global_context';
import { BlueCheckBox } from './room_outcome_form_styles';
import { RefundSection } from './refund_section';
import { OutOfScopeDiscussion } from './out_of_scope_discussion';
import { getInitialValue } from '../../util/call_disposition_helpers';
import { useCallSourceData } from '../../hooks/use_call_source_data';
import { useWillOfferOptions } from '../../hooks/use_will_offer_options';

interface DispositionDetailSelectionProps {
    formValues: {
        disposition: Disposition | undefined;
        shouldRefund?: boolean;
        refundReason?: RefundReason;
        training?: boolean;
        isOutOfScopeDiscussion?: boolean;
    };
    dispositionOptions: streem.api.CompanyRoomOutcomeConfig.ISection;
    customFieldOptions?: {
        refund?: streem.api.CompanyRoomOutcomeConfig.ISection;
        training?: streem.api.CompanyRoomOutcomeConfig.ISection;
        outOfScope?: streem.api.CompanyRoomOutcomeConfig.ISection;
    };
    outOfScopeReasons?: OutOfScopeReason[];
    setOutOfScopeReasons: React.Dispatch<React.SetStateAction<OutOfScopeReason[]>>;
}

export const DROPPED_RECONNECT = 'dropped - reconnect';

export const DispositionDetailSection = ({
    formValues,
    dispositionOptions,
    customFieldOptions,
    outOfScopeReasons,
    setOutOfScopeReasons,
}: DispositionDetailSelectionProps): JSX.Element => {
    const {
        disposition,
        shouldRefund = undefined,
        refundReason = undefined,
        training = undefined,
        isOutOfScopeDiscussion = undefined,
    } = formValues;
    const { companySettingsStore } = useGlobalStore();
    const { callSource } = useCallSourceData();
    const { setFieldValue, values } = useFormikContext<RoomOutcomeFormPayload>();
    const shouldShowRefundBox = useWillOfferOptions(customFieldOptions?.refund);

    const callSourceCode = callSource?.code.toLocaleLowerCase();

    const filteredOptions = useMemo(() => {
        return dispositionOptions.options
            .filter(option => {
                if (callSourceCode) {
                    return option.entry.callSourceCodes.includes(callSourceCode);
                } else return true;
            })
            .map(option => {
                return {
                    value: option.entry.code,
                    label: option.entry.label,
                };
            });
    }, [callSourceCode, dispositionOptions.options]);

    const handleToggleTrainingCall = () => {
        const { customFields } = values;
        if (customFields?.training === undefined) {
            return;
        }
        const { training } = customFields;
        setFieldValue('customFields', {
            ...customFields,
            training: !training,
        });
    };

    const handleToggleShouldRefund = () => {
        const { customFields } = values;
        if (!customFields?.refund) {
            return;
        }
        const { refund } = customFields;

        if (customFields.refund.shouldRefund) {
            setFieldValue('customFields', {
                ...customFields,
                refund: {
                    shouldRefund: !refund.shouldRefund,
                    reason: undefined,
                },
            });
            return;
        }
        setFieldValue('customFields', {
            ...customFields,
            refund: {
                ...customFields.refund,
                shouldRefund: !refund.shouldRefund,
            },
        });
    };

    const updateRequestRefundCheckbox = (dispositionCode: string, prevDispositionCode: string) => {
        if (!shouldRefund && dispositionCode === DROPPED_RECONNECT) {
            // Check
            handleToggleShouldRefund();
        }
        if (prevDispositionCode === DROPPED_RECONNECT && shouldRefund) {
            // Uncheck
            handleToggleShouldRefund();
        }
    };

    const handleDispositionChange = (val: { value: string; label: string }) => {
        const newDisposition = {
            code: val.value,
            label: val.label,
        };

        if (shouldShowRefundBox) {
            updateRequestRefundCheckbox(newDisposition.code, disposition.code);
        }
        setFieldValue('disposition', newDisposition);
    };

    const initialRefundReason = useMemo(() => {
        if (shouldRefund && refundReason) {
            return getInitialValue<RefundReason>(
                refundReason,
                customFieldOptions.refund?.options.map(option => ({
                    code: option.entry.code,
                    label: option.entry.label,
                })),
            );
        } else {
            return { value: '', label: '' };
        }
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, [shouldRefund, refundReason]);

    useLayoutEffect(() => {
        if (shouldRefund) {
            const refundReasonSearchInput = document.querySelector(
                '[aria-label="refund reason options"]',
            ) as HTMLInputElement;
            refundReasonSearchInput.focus();
        } else {
            const dispositionSearchInput = document.querySelector(
                '[aria-label="call disposition options"]',
            ) as HTMLInputElement;
            dispositionSearchInput.focus();
        }
    }, [shouldRefund]);

    return (
        <Box width="100%">
            <LabelSearchInput
                ariaLabel="call disposition options"
                label="Call Disposition"
                placeholder="Search here"
                handleChange={handleDispositionChange}
                options={filteredOptions}
                initialValue={getInitialValue<Disposition>(
                    disposition,
                    dispositionOptions.options.map(option => ({
                        code: option.entry.code,
                        label: option.entry.label,
                    })),
                )}
            />

            {shouldShowRefundBox && (
                <RefundSection
                    shouldRefund={shouldRefund}
                    handleToggleShouldRefund={handleToggleShouldRefund}
                    customFieldOptions={customFieldOptions}
                    initialRefundReason={initialRefundReason}
                />
            )}

            {companySettingsStore.ahsVirtualExpertEnabled && (
                <OutOfScopeDiscussion
                    displayOutOfScopeReasons={isOutOfScopeDiscussion}
                    outOfScopeReasons={outOfScopeReasons}
                    customFieldOptions={customFieldOptions}
                    setOutOfScopeReasons={setOutOfScopeReasons}
                />
            )}

            {customFieldOptions?.training && (
                <Row mt={'1.25rem'}>
                    <BlueCheckBox
                        id="training call"
                        dataTestId="training-call-checkbox"
                        description="Use this call for training sessions"
                        checked={training}
                        handleChange={handleToggleTrainingCall}
                    />
                </Row>
            )}
        </Box>
    );
};
