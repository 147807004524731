import {
    CallLogColumnProps,
    CallLogRow,
    columnBuilder,
} from '../components/calls/call_log_columns';
import { useGlobalStore } from './use_global_context';
import { TableColumn, useTheme } from '@streem/ui-react';
import { useEffect, useState } from 'react';

export interface CallLogColumnResults {
    callLogColumns: TableColumn<CallLogRow>[];
    gridTemplateColumnSizeBehavior: string;
}

/** Create columns for list view with pagination based on theme and company settings
 * configuration
 *
 */
export const useGetEmbedCallLogColumns = (): CallLogColumnResults => {
    const [callLogColumns, setCallLogColumns] = useState(null);
    const [gridTemplateColumnSizeBehavior, setGridTemplateColumnSizeBehavior] = useState(null);
    const theme = useTheme();
    const { companySettingsStore } = useGlobalStore();

    useEffect(() => {
        const hideFormattedAddress = companySettingsStore.hideFormattedAddressEnabled;
        const hideGPSLocations = !companySettingsStore.gpsEnabled;
        setGridTemplateColumnSizeBehavior(
            'minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(120px, auto) 120px',
        );

        setCallLogColumns(
            buildEmbedCallLogColumns({
                theme,
                hideFormattedAddress,
                hideGPSLocations,
            }),
        );
    }, [companySettingsStore.hideFormattedAddressEnabled, companySettingsStore.gpsEnabled, theme]);

    return { callLogColumns, gridTemplateColumnSizeBehavior };
};

function buildEmbedCallLogColumns(props: CallLogColumnProps): TableColumn<CallLogRow>[] {
    const columns = [
        columnBuilder.customerColumn(props),
        columnBuilder.teamMemberColumn(),
        columnBuilder.artifactColumn(props),
        columnBuilder.durationColumn(),
        columnBuilder.dateColumn(),
    ];
    return columns;
}
