import React from 'react';
import { useObserver } from 'mobx-react';
import { Column } from '@streem/ui-react';
import { useGlobalStore } from '../hooks/use_global_context';

export const PageColumn: React.FC<{ dataTestId?: string; style?: {} }> = ({
    dataTestId,
    style,
    children,
}) => {
    const { uiStore } = useGlobalStore();
    return useObserver(() => (
        <Column
            data-testid={dataTestId}
            width="100%"
            height={uiStore.bannerNotificationContent ? 'calc(100% - 74px)' : '100%'}
            {...style}
        >
            {children}
        </Column>
    ));
};
