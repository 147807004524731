import { streem } from 'streem-sdk-protobuf';
import { useCallSourceData } from './use_call_source_data';
import { useGlobalStore } from './use_global_context';

export const useWillOfferOptions = (
    configOptions: streem.api.CompanyRoomOutcomeConfig.ISection,
): boolean => {
    const { companySettingsStore } = useGlobalStore();
    const { callSource } = useCallSourceData();

    const callSourceCode = callSource?.code.toLocaleLowerCase();

    // I included a check for `callSource.code` here that way if a company has ahsVirtualExpertEnabled
    // but callSource is undefined we fail and go the else block allowing us to render a checkbox if options exist.
    if (companySettingsStore.ahsVirtualExpertEnabled && callSourceCode) {
        return (
            configOptions?.options.length > 0 &&
            configOptions.options.some(option =>
                option.entry.callSourceCodes?.includes(callSourceCode),
            )
        );
    } else {
        return configOptions?.options.length > 0;
    }
};
